.AnalyticsTotalContainer {
  min-height: 100vh;
  overflow-x: hidden; /* Updated line */
  overflow-y: hidden!important;
}


.DigitalFinanceImageContainer {
    background-image: url("https://abacuscambridge.com/hubfs/ACP---Innovation-Solutions---Digital-Financial-Services.jpg") !important;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    filter: brightness(0.7);
    height: 600px !important;
  } 