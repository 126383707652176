/*-------------------- Admin Registration CSS ------------------- */

.admin-register-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to top, #09203f 0%, #537895 100%);
  height: 100vh;
}

/*-------------------- Admin Login CSS ------------------- */

.admin-login-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #3a86ff;
  height: 100vh;
}

.admin-login-form-container {
  height: max-content;
  width: 32%;
  box-shadow: 20px 20px 60px #00000041, inset -20px -20px 60px #ffffff40;
  border-radius: 15px;
  padding: 30px;
  background-color: white;
}

.forgot-password {
  text-decoration: none;
  color: black;
  font-size: 15px;
  font-weight: 600;
  margin: 5px;
}

.admin-login-heading {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  color: #000000c5;
}
