.AnalyticsTotalContainer {
  min-height: 100vh;
  overflow-x: hidden; /* Updated line */
  overflow-y: hidden !important;
}

.ApplicationDevelopmentImageContainer {
  background-image: url("https://abacuscambridge.com/hubfs/ACP---Innovative-Solutions---End-to-End-Software-Engineering1920x540-px%20%282%29.jpg") !important;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  filter: brightness(0.7);
  height: 600px !important;
}

.ApplicationDevelopmentCardsContent {
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.9;
  margin: 0 0 1.4rem;
  text-decoration: none;
}

.ApplicationDevelopmentCardsContent:hover {
  box-shadow: 0 60px 100px rgba(0, 0, 0, 0.2);
}
.ApplicationDevelopmentButton {
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 400;
  height: auto;
  width: 150px;
  line-height: 1.1;
  padding: 24px 43px;
  position: relative;
  text-align: center;
  padding: 19px !important;
  text-decoration: none;
  transition: all 0.15s linear;
  white-space: normal;
  width: auto;
  word-break: break-word;
}
