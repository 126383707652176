.AnalyticsTotalContainer {
  min-height: 100vh;
  overflow-x: hidden; /* Updated line */
  overflow-y: hidden !important;
  background-color: #161616;
}

.AnalyticsImageContainer {
  background-image: url("https://abacuscambridge.com/hubfs/ACP---Innovative-Solutions---Advanced-Analytics-&-Business-Intelligence.jpg") !important;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 600px !important;
}
.content-container {
  position: absolute;
  top: 19% !important;
  width: 100%;
  text-align: left;

  color: #ffffff;
}

.analytics-content {
  margin: 20px;
  width: 80%;
  line-height: 2rem;
  color: white;
}
.AnalyticsSubHeadings {
  font-size: 30px !important;
  padding-left: 50px !important;
}
.AnalyticsHeading {
  font-size: 40px !important;
  font-weight: bold;
  padding-left: 50px !important;
}
.AnalyticsCardsContainer {
  margin-top: -400px;
}
.AnalyticsParagraph {
  font-size: 25px !important;
  padding-left: 50px !important;
}
@media (max-width: 1024px) {
  .AnalyticsHeading {
    font-size: 45px !important;
    font-weight: bold;
  }
}

@media (min-width: 280px) and (max-width: 653px) {
  .AnalyticsHeading {
    font-size: 30px !important;
    font-weight: bold;
  }
}

@media (min-width: 280px) and (max-width: 1023px) {
  .AnalyticsCardsContainer {
    margin-top: 9px !important;
  }
}

.AnalyticsEnquiryHeading {
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  font-size: 24px;
}
.cardsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: center;
}

.cardsContainer:hover {
  box-shadow: 0 60px 100px rgba(0, 0, 0, 0.2);
}

.AnalyticsList {
  padding: 0px !important;
  list-style-type: none;
}

.AnalyticsListContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AnalyticsActive {
  border-radius: 16px;
  background: #0f4494;
  border: none;
  color: white;
}
.AnalyticsInactive {
  color: blue;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .AnalyticsCardsContainer {
    margin-top: -400px !important;
  }
  .content-container {
    position: absolute;
    top: 19% !important;
    width: 100%;
    text-align: left;

    color: #ffffff;
  }
}
