.OpenFinanceImageContainer {
  background-image: url("https://abacuscambridge.com/hubfs/open-finance-1920x540.jpg") !important;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  filter: brightness(0.5);
  height: 400px !important;
  position: relative;
  padding: 12% 0 5% 0;
}



.OpenFinance-content-container {
  position: absolute;
  top: 12%;
  width: 100%;
  color: #ffffff;
}

/* styles.css */
.shadow-on-hover {
  transition: box-shadow 0.3s ease;
}

.shadow-on-hover:hover {
  box-shadow: 0 60px 100px rgba(0, 0, 0, 0.2);
}

@media (min-width: 375px) and(max-width:1023px) {
  .OpenFinanceCardsContainer {
    margin-top: 9px !important;
  }
}
@media (min-width: 1024px) {
  .OpenFinanceCardsContainer {
    margin-top: -300px !important;
  }
}
