@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Caveat:wght@400;700&family=Lobster&family=Monoton&family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Playfair+Display+SC:ital,wght@0,400;0,700;1,700&family=Playfair+Display:ital,wght@0,400;0,700;1,700&family=Roboto:ital,wght@0,400;0,700;1,400;1,700&family=Source+Sans+Pro:ital,wght@0,400;0,700;1,700&family=Work+Sans:ital,wght@0,400;0,700;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");
* {
  font-family: "PT Sans", sans-serif;
}

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "PT Sans";
}

#root {
  margin: 0;
  padding: 0;
}

.omnifinity-button {
  height: auto;
  width: auto;
  padding: 20px 35px;
  background-color: #0f4494;
  border: 1px solid #0f4494;
  color: #fff;
  border-radius: 3px;
  font-size: 17px;
}

.omnifinity-sub-heading {
  text-align: center;
  font-weight: 600;
  color: white;
  font-size: 38px;
}

.omnifinity-description {
  font-size: 16px;
  font-weight: 500;
  color: #aaaaaa;
}

.omnifinity-card-heading {
  font-size: 20px;
  font-weight: 500;
  color: white;
}

.omnifinity-card-para {
  font-size: 16px;
  font-weight: 500;
  text-align: start;
  color: #aaaaaa;
}

.omnifinity-button-active {
  border-radius: 10px;
  padding: 10px;
  font-size: 16px;
  outline: none;
  font-weight: 600;
  width: 170px;
  border: none;
  background: #fff;
  color: rgba(0, 0, 0, 0.745);
}
.omnifinity-button-inactive {
  font-size: 16px;
  font-weight: 600;
  width: 170px;
  color: white;
  border-radius: 10px;
  padding: 10px;
  border: none;
  outline: none;
  background: #3c3c3c;
  cursor: pointer;
}

.omnifinity-label {
  color: #e3e5e7;
  font-weight: 500;
  font-size: 16px;
  text-align: start;
}

@media screen and (max-width: 1200px) {
  .omnifinity-sub-heading {
    text-align: center;
    font-weight: 600;
    color: white;
    font-size: 36px;
  }
}

@media screen and (max-width: 512px) {
  .omnifinity-sub-heading {
    text-align: center;
    font-weight: 600;
    color: white;
    font-size: 34px;
  }
  .omnifinity-card-heading {
    font-size: 20px;
    font-weight: 500;
    color: white;
  }

  .omnifinity-card-para {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #aaaaaa;
  }
  .omnifinity-button {
    height: auto;
    width: auto;
    padding: 12px 25px;
    background-color: #0f4494;
    border: 1px solid #0f4494;
    color: #fff;
    border-radius: 3px;
    font-size: 15px;
  }
}
