.detailed-blog-main-container{
    width:100%;
    display: flex;
    flex-direction: column;
    gap:20px;
   background-color: #000;
   padding:20px 40px;
   padding-top:100px;
}
.blogs-main-heading{
    font-size:28px;
    font-weight:600;
    margin:0px;
    text-align: center;
    color:#fff;
}
.blogs-sub-heading{
    font-size:24px;
    font-weight:600;
    margin:0px;
    color:#fff;
}
.blogs-sub-heading-2{
    font-size:18px;
    font-weight:600;
    margin:0px;
    color:#fff;
}
.blogs-description{
    font-size:18px;
    margin:0px;
    color:#fff;
    font-weight:400 !important;
}
.blogs-left-right-main-container{
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    gap:50px;
    margin-bottom: 40px;
}
.blogs-left-container{
    width:45%;
}
.blogs-right-container{
    width:45%;
}
.polarion-image-container{
    height:400px;
    width:400px;
    margin-right:80px;
}
.blogs-image{
    width:100%;
    height: 100%;
}
.blog-sub-list{
    list-style-type: circle;
}
@media (max-width:768px){
    .blogs-left-right-main-container{
        flex-direction: column-reverse;
    }
    .blogs-left-container{
    width:90%;
}
.blogs-right-container{
    width:90%;
}
.polarion-image-container{
    margin:0px;
}
}