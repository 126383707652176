.saphana-first-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65vh;
  width: 100%;
  position: relative;
}

.saphana-bg-container {
  background-image: url("../../../../images/Enterprise/SapHana/saphanabg.webp");
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: brightness(0.6);
  background-size: cover;
  background-position: center;
}
.saphana-bg-sub-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  z-index: 1;
}
.saphana-enterprise-system-text {
  color: #fff;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 4px;
}
.saphana-bg-heading {
  color: #fff;
  font-size: 50px;
  margin: 0px;
  width: 50%;
}
.saphana-bg-sub-heading {
  color: #fff;
  font-size: 24px;
  margin: 0px;
  width: 45%;
}
.saphana-second-main-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  gap: 50px;
}
.saphana-second-main-left-container {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
}
.saphana-second-main-left-descripton {
  color: #444;
  font-size: 18px;
  line-height: 1.9;
}
.enquiry-form-main-container {
  border-radius: 10px;
  width: 40%;
  position: relative;
  top: 0;
  margin-top: -270px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.enquiry-form-main-heading {
  font-size: 24px;
  margin: 0px;
  text-align: center;
  color: #fff;
  background-color: #0f4494;
  border-radius: 10px 10px 0px 0px;
  padding: 20px 0px;
}
.enquiry-form-sub-heading {
  font-size: 18px;
  margin: 10px 0px 0px 0px;
  text-align: center;
  color: #494a52;
}
.enquiry-form-sub-container {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.enquiry-form-input-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.enquiry-form-label-name {
  color: #494a52;
  font-size: 14px;
  margin: 0px;
}
.equiry-form-input-field {
  border: 1px solid #bbb;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
}
.enquiry-form-submit-button {
  background-color: #0f4494;
  border-radius: 25px;
  outline: none;
  border: none;
  color: #fff;
  padding: 6px 24px;
}

.saphana-systems-card-main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
}

.saphana-systems-card-main-heading {
  width: 80%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.saphana-systems-card-flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  margin-top: 2%;
  gap: 7%;
  width: 80%;
}
.saphana-systems-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 28%;
  height: 515px;
  padding: 30px;
}
.saphana-systems-card-container:hover {
  box-shadow: 0 43px 70px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}
.saphana-systems-card-image {
  max-width: 60%;
  height: auto;
}
.saphana-systems-card-heading {
  font-size: 33px;
  color: #134391;
  font-weight: 400;
  margin: 0px;
  font-style: normal;
  text-align: center;
}
.saphana-systems-card-description {
  font-size: 16px;
  color: #444444;
  margin: 0px;
  line-height: 1.5rem;
  overflow: hidden;
}

.saphana-last-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0px;
}
.saphana-last-description {
  width: 80%;
  line-height: 1.8rem;
  font-size: 18px;
}

/* ============================== SAP Business By Design CSS =================================== */

.sap-business-by-design-bg-container {
  background-image: url("../../../../images/Enterprise/SapBusiness/sapbusinessbg.jpg");
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: brightness(0.6);
  background-size: cover;
  background-position: center;
}

/* ============================= SAP Business One CSS ================================== */

.sap-business-one-bg-container {
  background-image: url("../../../../images/Enterprise/SapBusinessOne/sapbusinessonebg.webp");
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: brightness(0.4);
  background-size: cover;
  background-position: center;
}

/* ========================= SAP Customer CSS =============================== */

.sap-customer-bg-container {
  background-image: url("../../../../images/Enterprise/SapCustomer/sapcustomerbg.jpg");
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: brightness(0.4);
  background-size: cover;
  background-position: center;
}

/* =========================== SAP Ariba CSS ================================== */

.sap-ariba-bg-container {
  background-image: url("../../../../images/Enterprise/SapAriba/saparibabg.jpg");
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: brightness(0.6);
  background-size: cover;
  background-position: center;
}

/* ====================== ManagedCloud CSS ====================================== */

.manage-cloud-bg-container {
  background-image: url("../../../../images/Enterprise/SapAriba/saparibabg.jpg");
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: brightness(0.6);
  background-size: cover;
  background-position: center;
}

.managed-cloud-two-cards-main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px 0px;
}

.managed-cloud-two-cards-buttons-container {
  display: flex;
  justify-content: space-between;
  width: 35%;
}

.service-active {
  background-color: rgb(38, 109, 201);
  outline: none;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 500;
  padding: 8px;
  border-radius: 8px;
}

.service-deactive {
  color: rgb(38, 109, 201);
  font-size: 14px;
  font-weight: 500;
  outline: none;
  border: none;
  background-color: transparent;
}

.reveal-card-container {
  display: flex;
  padding: 10px;
  margin-top: 20px;
  width: 80%;
}

.managed-cloud-two-cards-container {
  display: flex;
  justify-content: space-between;
}

.managed-cloud-two-cards-text-container {
  width: 45%;
}

.managed-cloud-heading {
  font-size: 30px;
  margin-top: 20px;
  font-weight: 500;
  color: rgb(78, 78, 78);
}

.managed-cloud-description {
  font-size: 17px;
  line-height: 1.8rem;
}

.managed-card-image {
  width: 45%;
}

.managed-last-heading {
  font-size: 40px;
  width: 80%;
  margin-top: 20px;
}

/* ============================== Staff Agumentation CSS ======================== */

.staff-steps-main-continer {
  display: flex;
  width: 70%;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.staff-steps-heading {
  width: 40%;
  font-size: 50px;
  font-weight: 400;
  color: #444444;
  padding: 10px;
}

.staff-steps-container {
  width: 55%;
  display: flex;
  flex-direction: column;
}

.staff-active {
  border-radius: 16px;
  background: #2c5ca4;
  padding: 5px;
  border: none;
  color: white;
  margin: 5px;
}
.staff-inactive {
  padding: 5px;
  color: #2c5ca4;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  margin: 5px;
}

.staff-steps-text-content {
  font-size: 30px;
}

/* ========================================= SAP On Google CSS =========================== */
.sap-on-google-bg-container {
  background-image: url("../../../../images/Solutions/SapOnGoogle/sapongooglebg.jpg");
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: brightness(0.6);
  background-size: cover;
  background-position: center;
}

.sap-on-google-systems-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 45%;
  height: 515px;
  padding: 30px;
}
.sap-on-google-systems-card-container:hover {
  box-shadow: 0 43px 70px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}

/* ======================================== Retail End to End CSS ========================= */
.retail-end-to-end-bg-container {
  background-image: url("../../../../images/Solutions/RetailEndToEnd/retailendtoendbg.jpg");
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: brightness(0.6);
  background-size: cover;
  background-position: center;
}

.retail-end-to-end-first-container {
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(15, 68, 148, 1),
    rgba(44, 60, 111, 1)
  );
  padding: 9%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.retail-end-to-end-text-container {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.retail-end-to-end-heading {
  font-size: 40px;
  font-weight: 600;
  color: white;
}

.retail-end-to-end-para {
  font-size: 25px;
  text-align: center;
  font-weight: 500;
  color: white;
}

.retail-end-to-end-banner-container {
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(15, 68, 148, 1),
    rgba(44, 60, 111, 1)
  );
  padding: 6%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.retail-end-to-end-banner-heading {
  font-size: 40px;
  color: white;
  font-weight: 600;
}

.retail-end-to-end-sub-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

/* ================================================== Sap Moderization CSS =================================*/

.sap-moderization-bg-container {
  background-image: url("../../../../images/Solutions/SapModerization/sapmoderizationbg.jpg");
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: brightness(0.6);
  background-size: cover;
  background-position: center;
}

.sap-moderization-card-heading {
  font-size: 20px;
  color: #134391;
  font-weight: 400;
  margin: 0px;
  font-style: normal;
  text-align: center;
}
.sap-moderization-card-description {
  font-size: 14px;
  color: #444444;
  margin: 0px;
  line-height: 1.5rem;
  overflow: hidden;
}

/* ========================================== News CSS ======================================= */

.news-bg-container {
  background-image: url("../../../../images/news-bg.webp");
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: brightness(0.6);
  background-size: cover;
  background-position: center;
}

.news-bg-sub-container {
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.news-bg-heading {
  color: #fff;
  font-size: 50px;
  margin: 0px;
  width: 40%;
  text-align: center;
}

.news-cards-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.news-cards-holding-container {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
}

.news-card {
  width: 40%;
  padding: 30px;
  background-color: #000000;
  box-shadow: 0 43px 70px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  margin: 30px;
  cursor: pointer;
}

.news-card-body {
  padding: 10px 0px;
}

.news-image {
  width: 100%;
  height: 300px;
}

.news-card-title {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}

.news-card-text {
  font-size: 13px;
  color: #f0f0f0;
}

.news-icons-container {
  display: flex;
  gap: 4px;
}

.news-icon {
  color: black;
  background-color: gray;
  font-size: 20px;
  border-radius: 2px;
}

.news-icon:hover {
  color: #0c5394;
  background-color: white;
  border-radius: 2px;
}

.blogs-main-container {
  background-color: #161616;
}

@media screen and (max-width: 768px) {
  .saphana-second-main-container {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .saphana-second-main-left-container {
    width: 90%;
  }
  .enquiry-form-main-container {
    width: 90%;
    margin-top: 0px;
  }
  .saphana-systems-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 45%;
    height: 430px;
    padding: 10px;
  }

  /* ===================== Staff Agumentation CSS ========================= */
  .staff-steps-heading {
    width: 44%;
    font-size: 40px;
    font-weight: 400;
    color: #444444;
    padding: 8px;
  }
  .staff-steps-main-continer {
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  /* =================================== Managed Cloud CSS ===================================== */
  .managed-cloud-two-cards-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 80%;
  }

  .service-active {
    background-color: rgb(38, 109, 201);
    outline: none;
    border: none;
    color: white;
    font-size: 14px;
    font-weight: 500;
    /* margin: 14px; */
    margin-bottom: 15px;
    padding: 8px;
    border-radius: 8px;
  }

  .managed-cloud-two-cards-text-container {
    width: 80%;
  }

  .managed-cloud-two-cards-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .managed-cloud-heading {
    font-size: 28px;
    margin-top: 20px;
    font-weight: 500;
    color: rgb(78, 78, 78);
  }

  .managed-cloud-description {
    font-size: 15px;
    line-height: 1.5rem;
  }

  .managed-card-image {
    width: 80%;
  }
  .managed-last-heading {
    font-size: 35px;
    width: 80%;
    margin-top: 20px;
  }

  /* ==================================== SAP On Google CSS ============================== */

  .sap-on-google-systems-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 75%;
    height: 515px;
    padding: 30px;
  }

  /* ================================= Retail end to end CSS ========================== */

  .retail-end-to-end-first-container {
    width: 100%;
    background-image: linear-gradient(
      to bottom,
      rgba(15, 68, 148, 1),
      rgba(44, 60, 111, 1)
    );
    padding: 9%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .retail-end-to-end-banner-heading {
    font-size: 35px;
    color: white;
    font-weight: 600;
  }

  /* =============================================== News CSs =================================== */

  .news-bg-heading {
    color: #fff;
    font-size: 40px;
    margin: 0px;
    width: 80%;
    text-align: center;
  }

  .news-cards-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .news-cards-holding-container {
    display: flex;
    flex-wrap: wrap;
    width: 99%;
  }

  .news-card {
    width: 90%;
    padding: 30px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 43px 70px rgba(0, 0, 0, 0.12);
    border-radius: 15px;
    margin: 30px;
  }

  .news-image {
    width: 100%;
    height: 300px;
  }

  .news-card-title {
    font-size: 20px;
    font-weight: 500;
    color: black;
  }

  .news-card-text {
    font-size: 13px;
  }

  .news-icons-container {
    display: flex;
    gap: 4px;
  }

  .news-icon {
    color: #fff;
    background-color: #0c5394;
    font-size: 20px;
    border-radius: 2px;
  }

  .news-icon:hover {
    color: #0c5394;
    background-color: white;
    border-radius: 2px;
  }
}

@media screen and (max-width: 512px) {
  .saphana-systems-card-flex-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    margin-top: 2%;
    padding-left: 0px;
    gap: 7%;
    width: 80%;
  }
  .saphana-systems-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 80%;
    height: 470px;
    padding: 20px;
  }
  .saphana-systems-card-heading {
    font-size: 28px;
    color: #134391;
    font-weight: 400;
    margin: 0px;
    font-style: normal;
    text-align: center;
  }
  .saphana-systems-card-description {
    font-size: 14px;
    color: #444444;
    margin: 0px;
    line-height: 1.5rem;
    overflow: hidden;
  }
  .saphana-bg-sub-heading {
    color: #fff;
    font-size: 24px;
    margin: 0px;
    width: 80%;
  }
  .saphana-bg-heading {
    color: #fff;
    font-size: 40px;
    margin: 0px;
    width: 80%;
  }

  .saphana-second-main-left-descripton {
    font-size: 17px;
  }

  .saphana-last-description {
    font-size: 15px;
  }

  /* =================================== Managed Cloud CSS ===================================== */
  .managed-cloud-two-cards-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 80%;
  }

  .service-active {
    background-color: rgb(38, 109, 201);
    outline: none;
    border: none;
    color: white;
    font-size: 14px;
    font-weight: 500;
    /* margin: 14px; */
    margin-bottom: 15px;
    padding: 8px;
    border-radius: 8px;
  }

  .managed-cloud-two-cards-text-container {
    width: 80%;
  }

  .managed-cloud-two-cards-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .managed-cloud-heading {
    font-size: 28px;
    margin-top: 20px;
    font-weight: 500;
    color: rgb(78, 78, 78);
  }

  .managed-cloud-description {
    font-size: 15px;
    line-height: 1.5rem;
  }

  .managed-card-image {
    width: 80%;
  }
  .managed-last-heading {
    font-size: 35px;
    width: 80%;
    margin-top: 20px;
  }

  /* ================================ Staff Agumentation CSS  ============================= */
  .staff-steps-main-continer {
    display: flex;
    flex-direction: column;
    width: 80%;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .staff-steps-heading {
    width: 90%;
    font-size: 35px;
    font-weight: 400;
    color: #444444;
    padding: 10px;
  }
  .staff-steps-container {
    width: 90%;
    display: flex;
    flex-direction: column;
  }
  .staff-active {
    border-radius: 12px;
    background: #2c5ca4;
    padding: 11px;
    border: none;
    color: white;
    margin: 5px;
  }
  .staff-steps-text-content {
    font-size: 25px;
  }

  /* ====================================== SAP On Google CSS =========================*/
  .sap-on-google-systems-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 80%;
    height: 515px;
    padding: 30px;
  }

  /*================================== Retail End to End CSS =============================*/
  .retail-end-to-end-banner-heading {
    font-size: 30px;
    color: white;
    text-align: center;
    font-weight: 600;
  }

  .retail-end-to-end-sub-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .retail-end-to-end-text-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /* ========================================== NEWS CSS ==================================== */

  .news-bg-heading {
    color: #fff;
    font-size: 30px;
    margin: 0px;
    width: 40%;
    text-align: center;
  }

  .news-cards-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .news-cards-holding-container {
    display: flex;
    flex-wrap: wrap;
    width: 98%;
  }

  .news-card {
    width: 90%;
    padding: 30px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 43px 70px rgba(0, 0, 0, 0.12);
    border-radius: 15px;
    margin: 30px;
  }

  .news-image {
    width: 100%;
    height: 150px;
  }

  .news-card-title {
    font-size: 18px;
    font-weight: 500;
    color: black;
  }

  .news-card-text {
    font-size: 13px;
  }

  .news-icons-container {
    display: flex;
    gap: 4px;
  }

  .news-icon {
    color: #fff;
    background-color: #0c5394;
    font-size: 20px;
    border-radius: 2px;
  }

  .news-icon:hover {
    color: #0c5394;
    background-color: white;
    border-radius: 2px;
  }
}
