.hamburger-icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 10px 10px 0px;
  cursor: pointer;
  color: black;
}

.hamburger-icon:hover {
  color: #fff;
}

.sidebar-icons-container {
  margin-top: 26px;
  min-height: 20vh;
  color: #000;
}

.sidebar-container {
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.hamburger-icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 10px 10px 0px;
  cursor: pointer;
}
.sidebar-logo-name {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  text-align: center;
  margin-left: 70px;
  margin-bottom: 10%;
}
.sidebar-logo-image {
  width: 100%;
  border-radius: 60%;
}

.menu-active {
  width: 90% !important;
  margin-left: 3px;
  border-radius: 10px;
  background-color: #293b51;
  color: #f0f0f0;
}
