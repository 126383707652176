.contactbgcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  height: 400px;
  position: relative;
  padding: 10% 0 10% 0;
  filter: brightness(0.4);
  background-position: center center;
  background-repeat: no-repeat;

  background-image: url("https://abacuscambridge.com/hubfs/Untitled%20design%20%285%29.jpg");
}

.totalContactUsContainer {
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: #161616;
}

.contactHeading {
  position: absolute;
  width: 100%;
  top: 25%;

  color: #ffffff;
  font-weight: 900;
}

.contact-us-card-description {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #aaaaaa;
}

.contact-us-input {
  background-color: #474747;
  color: #fff;
  border: none;
}

.contact-us-input::placeholder {
  color: #cccaca;
}

.contact-us-icon {
  font-size: 80px;
  color: white;
}
.button-loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #000; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 0.8s linear infinite;
  display: inline-block;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-button:disabled {
  background-color: #ccc;
}
