@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Caveat:wght@400;700&family=Lobster&family=Monoton&family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Playfair+Display+SC:ital,wght@0,400;0,700;1,700&family=Playfair+Display:ital,wght@0,400;0,700;1,700&family=Roboto:ital,wght@0,400;0,700;1,400;1,700&family=Source+Sans+Pro:ital,wght@0,400;0,700;1,700&family=Work+Sans:ital,wght@0,400;0,700;1,700&display=swap");

.homepage-carousel-caption-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  transform: translate(-50%, -50%);
  text-align: center !important;
  z-index: 2;
  padding: 20px;
}
.carouselHeading {
  font-size: 65px !important;
}

.carouselParagraph {
  font-size: 25px !important;
}

.HomePageTotalContainer {
  min-height: 100vh;
  background-color: #161616;
  overflow-x: hidden;
  overflow-y: hidden;
}

.HomePageVideoContainer {
  position: relative;
  background: greenDark;
}

.HomePagevideoElement {
  width: 100%;
  height: 300px; /* Adjust this value based on your requirements */
  object-fit: cover; /* Ensures the video covers the entire container */
}

.HomeInactive:hover {
  background-color: none !important;
}

.home-reveal {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: all 1s ease;
}

.home-reveal.active {
  transform: translateY(0px);
  opacity: 1;
}

.HomeContentContainer {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.home-page-content-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 10px;
  padding-left: 3px;
}
.home-page-content-card.active {
  transform: translateY(0px);
  opacity: 1;
}

.home-page-content-para {
  text-align: start;
}

.home-page-last-cards-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
  gap: 2%;
}

.home-page-last-card {
  width: 32%;
  height: 490px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #000000;
  border-radius: 10px;
}

.home-page-last-card-text-container {
  padding: 10px;
}

.home-page-last-card-image {
  width: 100%;
  height: 220px;
  object-fit: contain;
  border-radius: 10px;
  padding: 10px;
}

.home-slider {
  width: 85%;
}

.carouselBanner {
  z-index: -1;
  filter: brightness(0.7);
  height: 100%;
  width: 100%;
  background-color: #02070cb8 !important;
  /* background: rgba(12, 4, 4, 0.238); */
}

.home-slider-image {
  width: 170px;
}

@media (max-width: 991px) {
  .carouselHeading {
    font-size: 40px !important;
  }
  .carouselParagraph {
    font-size: 20px !important;
  }
  .home-page-last-card {
    margin-top: 25px;
    width: 90%;
    height: 490px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #000000;
    border-radius: 10px;
  }

  .home-slider {
    width: 100%;
  }

  .HomeContentContainer {
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: all 1s ease;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .home-page-content-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 10px;
    padding-left: 3px;
  }
}

@media screen and (max-width: 720px) {
  .home-slider {
    width: 50%;
  }
  .home-slider-image {
    width: 100px;
  }
}
