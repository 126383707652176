.aboutus-main-container {
  background-color: #161616;
}

.aboutus-first-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 100%;
  position: relative;
}

.aboutus-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../images/aboutbanner.webp");
  filter: brightness(0.7);
  background-size: cover;
  background-position: center;
}

.aboutus-first-text-container {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.aboutus-heading {
  font-weight: 500;
  color: white;
  font-size: 50px;
  text-align: center;
  width: 50%;
  margin: 0px;
}

.aboutus-description {
  color: white;
  font-size: 20px;
  text-align: center;
  width: 50%;
}

.aboutus-second-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 40px;
}

.aboutus-second-text-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.aboutus-second-heading {
  font-weight: 500;
  color: black;
  font-size: 43px;
  text-align: center;
}

.aboutus-second-description {
  color: black;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.9;
  padding: 10px;
}

.aboutus-third-image-text-container {
  width: 80%;
  display: flex;
  gap: 5%;
}

.aboutus-third-heading {
  font-weight: 500;
  font-size: 40px;
  color: black;
  text-align: center;
  margin: 0px;
}

.aboutus-third-image-container {
  width: 50%;
}
.aboutus-third-image {
  width: 100%;
}

.aboutus-third-text-container {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.aboutus-our-global-point-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.aboutus-world-map-image {
  width: 100%;
}

.aboutus-global-colors-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.aboutus-global-color-image-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 22%;
  padding: 20px;
  height: max-content;
}

.aboutus-global-color-image-container:hover {
  border-radius: 10px;
  box-shadow: 0 43px 70px rgba(0, 0, 0, 0.12);
}

.aboutus-global-color-image {
  height: 120px;
  width: 120px;
}

.aboutus-global-color-text {
  font-size: 33px;
  font-weight: 400;
  color: rgb(52, 52, 233);
  margin: 0px;
}

.aboutus-our-values-conatainer {
  width: 80%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5%;
}

.aboutus-our-values-text-image-container {
  width: 42%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  margin: 2%;
  gap: 10px;
}

.aboutus-our-values-text-image-container:hover {
  box-shadow: 0 43px 70px rgba(0, 0, 0, 0.12);
}

.aboutus-our-values-card-heading {
  font-weight: 500;
  font-size: 20px;
  color: rgb(30, 30, 30);
  margin: 0px;
}

.our-leadrship-caontainer {
  width: 80%;
}

.our-leadership-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.our-leadership-cards-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.our-leadership-card {
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  width: 28%;
  align-items: center;
  box-shadow: 0 43px 70px rgba(0, 0, 0, 0.12);
}

.our-leader-image {
  width: 55%;
}

.aboutus-last-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

@media screen and (max-width: 1200px) {
  .aboutus-main-container {
    display: flex;
    flex-direction: column;
    gap: 8%;
  }
  .aboutus-first-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    width: 100%;
    position: relative;
  }
  .aboutus-first-text-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  .aboutus-heading {
    font-weight: 500;
    color: white;
    font-size: 50px;
    text-align: center;
    width: 80%;
    margin: 0px;
  }
  .aboutus-description {
    color: white;
    font-size: 20px;
    text-align: center;
    width: 70%;
  }
  .aboutus-second-text-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .aboutus-third-image-text-container {
    width: 90%;
    height: 375px;
    display: flex;
    gap: 3%;
  }
  .aboutus-third-image-container {
    width: 57%;
  }
  .aboutus-third-text-container {
    width: 37%;
    display: flex;
    flex-direction: column;
  }

  .aboutus-third-heading {
    font-weight: 500;
    font-size: 32px;
    color: black;
    width: 100%;
    text-align: center;
    margin: 0px;
  }
  .aboutus-second-description {
    color: black;
    font-size: 16px;
    font-weight: 400;
    overflow: hidden;
    line-height: 1.9;
    padding: 10px;
  }
  .aboutus-third-heading {
    font-weight: 500;
    font-size: 42px;
    color: black;
    width: 100%;
    text-align: center;
    margin: 0px;
  }
  .aboutus-global-colors-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .aboutus-global-color-image-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 45%;
    padding: 20px;
    height: max-content;
  }
}

@media screen and (max-width: 512px) {
  .aboutus-first-text-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .aboutus-first-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    width: 100%;
    position: relative;
  }
  .aboutus-heading {
    font-weight: 500;
    color: white;
    font-size: 38px;
    text-align: center;
    width: 80%;
    margin: 0px;
  }
  .aboutus-description {
    color: white;
    font-size: 18px;
    text-align: center;
    width: 70%;
  }
  .aboutus-second-text-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .aboutus-second-heading {
    font-weight: 500;
    color: black;
    font-size: 38px;
    text-align: center;
  }
  .aboutus-second-description {
    color: black;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.9;
    padding: 10px;
  }
  .aboutus-third-image-text-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 5%;
  }
  .aboutus-third-image-container {
    width: 100%;
  }
  .aboutus-third-text-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .aboutus-third-heading {
    font-weight: 500;
    font-size: 30px;
    color: black;
    text-align: center;
    margin: 0px;
  }
  .aboutus-global-colors-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .aboutus-global-color-image-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    height: max-content;
  }
  .aboutus-global-color-image {
    height: 110px;
    width: 110px;
  }
  .aboutus-global-color-text {
    font-size: 30px;
    font-weight: 400;
    color: rgb(52, 52, 233);
    margin: 0px;
  }

  .aboutus-our-values-conatainer {
    width: 90%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 5%;
  }

  .aboutus-our-values-text-image-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    margin: 2%;
  }
}
