.forgot-password-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 90vh;
}

.forgot-password-form {
  height: max-content;
  width: 32%;
  box-shadow: 20px 20px 60px #00000041, inset -20px -20px 60px #ffffff40;
  border-radius: 15px;
  padding: 30px;
  background-color: rgb(219, 235, 247);
}

.forgot-password-heading {
  color: black;
  font-size: 25px;
  font-weight: 500;
}
