@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap");

.footer-social-media-icons-main-container {
  display: inline-flex;
  margin-top: 5%;
}
.footer-social-media-icons-main-container .contact-page-social-icon-container {
  margin: 0 20px;
  margin-bottom: 10%;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.footer-social-media-icons-main-container
  .contact-page-social-icon-container
  span {
  display: block;
  height: 35px;
  width: 35px;
  background: #131413;
  border-radius: 50%;
  position: relative;
  z-index: 2;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.footer-social-media-icons-main-container
  .contact-page-social-icon-container
  span
  i {
  line-height: 60px;
  font-size: 25px;
}
.footer-social-media-icons-main-container
  .contact-page-social-icon-container
  .contact-page-social-icon-tooltip {
  position: absolute;
  top: 0;
  z-index: 1;
  background: #fff;
  color: #fff;
  padding: 10px 18px;
  font-size: 20px;
  font-weight: 500;
  border-radius: 25px;
  opacity: 0;
  pointer-events: none;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.footer-social-media-icons-main-container
  .contact-page-social-icon-container:hover
  .contact-page-social-icon-tooltip {
  top: -70px;
  opacity: 1;
  pointer-events: auto;
}
.contact-page-social-icon-container .contact-page-social-icon-tooltip:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background: #fff;
  left: 50%;
  bottom: -6px;
  transform: translateX(-50%) rotate(45deg);
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.footer-social-media-icons-main-container
  .contact-page-social-icon-container:hover
  span {
  color: #fff;
}
.footer-social-media-icons-main-container
  .contact-page-social-icon-container:hover
  span,
.footer-social-media-icons-main-container
  .contact-page-social-icon-container:hover
  .contact-page-social-icon-tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
}
.footer-social-media-icons-main-container
  .contact-page-facebook-container:hover
  span,
.footer-social-media-icons-main-container
  .contact-page-facebook-container:hover
  .contact-page-social-icon-tooltip,
.footer-social-media-icons-main-container
  .contact-page-facebook-container:hover
  .contact-page-social-icon-tooltip:before {
  background: #3b5999;
}
.footer-social-media-icons-main-container
  .contact-page-twitter-container:hover
  span,
.footer-social-media-icons-main-container
  .contact-page-twitter-container:hover
  .contact-page-social-icon-tooltip,
.footer-social-media-icons-main-container
  .contact-page-twitter-container:hover
  .contact-page-social-icon-tooltip:before {
  background: #46c1f6;
}
.footer-social-media-icons-main-container
  .contact-page-instagram-container:hover
  span,
.footer-social-media-icons-main-container
  .contact-page-instagram-container:hover
  .contact-page-social-icon-tooltip,
.footer-social-media-icons-main-container
  .contact-page-instagram-container:hover
  .contact-page-social-icon-tooltip:before {
  background: #e1306c;
}
.footer-social-media-icons-main-container
  .contact-page-linkedin-container:hover
  span,
.footer-social-media-icons-main-container
  .contact-page-linkedin-container:hover
  .contact-page-social-icon-tooltip,
.footer-social-media-icons-main-container
  .contact-page-linkedin-container:hover
  .contact-page-social-icon-tooltip:before {
  background: #0a66c2;
}
.footer-social-media-icons-main-container
  .contact-page-youtube-container:hover
  span,
.footer-social-media-icons-main-container
  .contact-page-youtube-container:hover
  .contact-page-social-icon-tooltip,
.footer-social-media-icons-main-container
  .contact-page-youtube-container:hover
  .contact-page-social-icon-tooltip:before {
  background: #de463b;
}

.contact-page-social-contact-page-social-icon-container {
  width: 70%;
  height: 70%;
}

.contact-page-social-contact-page-social-icon-container-anchor {
  text-decoration: none;
  color: black;
}

@media screen and (max-width: 1025px) {
  .footer-social-media-icons-main-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row !important;
  }
}

.text-content,
.footerheading {
  padding: 0px;
  line-height: 1.4;

  list-style-type: none;
}
.footerheading {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.footer-links-list {
  list-style-type: none;
}

.footer-link {
  font-size: 16px;
  color: white;
  text-decoration: none;
}
