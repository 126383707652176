.add-banner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 90vh;
}

.add-banner-form-container {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  gap: 5px;
  box-shadow: 20px 20px 60px #00000041, inset -20px -20px 60px #ffffff40;

  border-radius: 10px;
}

.add-banner-heading {
  font-weight: 600;
  text-align: center;
  margin: 10px 0px;
}

.add-banner-input-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.add-banner-label {
  font-size: 16px;
  font-weight: 500;
  color: black;
}

.add-banner-input {
  height: 35px;
  padding: 10px;
  border-radius: 5px;
  background-color: rgb(230, 230, 230);
  border: 1px solid rgb(200, 200, 200);
  width: 90%;
}

.add-banner-file-input {
  height: 40px;
  padding: 6px 8px;
  border-radius: 5px;
  background-color: rgb(230, 230, 230);
  border: 1px solid rgb(200, 200, 200);
  width: 90%;
}

.add-banner-button {
  width: 40%;
  background-color: rgb(17, 155, 17);
  border: none;
  outline: none;
  cursor: pointer;
  color: white;
  font-size: 16px;
  height: 35px;
  border-radius: 5px;
}

.add-service-button {
  width: 40%;
  background-color: rgb(19, 88, 227);
  border: none;
  outline: none;
  font-weight: 500;
  cursor: pointer;
  color: white;
  font-size: 16px;
  height: 35px;
  border-radius: 5px;
}
