.all-banners-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.banners-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  width: 98%;
  gap: 2%;
}

.banner-item {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 28%;
  height: 350px;
}

.banner-image {
  width: 100%;
  height: 150px;
  border-radius: 8px;
}

.banner-details {
  margin-top: 10px;
}

.banner-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.banner-description {
  font-size: 1rem;
}

.service-description {
  overflow: hidden;
  height: 120px;
}
.button-container {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.update-button {
  background-color: #1d6bbe;
  color: #fff;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.delete-button {
  background-color: #da1d1d;
  color: #fff;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 0 auto;
}

.modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.update-banner-form-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  gap: 5px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.update-banner-button {
  background-color: #1d6bbe;
  color: #fff;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.update-banner-textarea {
  width: 90%;
  border-radius: 5px;
  background-color: rgb(230, 230, 230);
  border: 1px solid rgb(200, 200, 200);
}
