.managed-services-main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #161616;
}

.managed-services-first-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
  width: 100%;
  position: relative;
}

.managed-services-bg-container {
  background-image: url("../../../images/Managed/managedbg.webp");
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: brightness(0.7);
  background-size: cover;
  background-position: center;
}
.managed-services-bg-sub-container {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.managed-services-bg-heading {
  color: #fff;
  font-size: 50px;
  text-align: center;
  margin: 0px;
}
.managed-services-bg-sub-heading {
  color: #fff;
  font-size: 24px;
  margin: 0px;
}
.managed-services-second-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.managed-services-main-description {
  margin: 20px;
  width: 80%;
  line-height: 2rem;
}
.managed-services-card-main-heading {
  font-size: 43px;
  color: #000;
  margin: 0px 0px 23px;
  text-align: center;
  width: 70%;
  font-weight: 700;
}

.managed-services-card-main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
}

.managed-services-card-flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  margin-top: 2%;
  gap: 7%;
  width: 80%;
}
.managed-services-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 27%;
  padding: 30px;
  height: 400px;
  margin: 5px;
}
.managed-services-card-container:hover {
  box-shadow: 0 43px 70px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}
.managed-services-card-image {
  max-width: 100%;
  height: auto;
}
.managed-services-card-heading {
  font-size: 24px;
  color: #134391;
  font-weight: 400;
  margin: 0px;
  font-style: normal;
  text-align: center;
}
.managed-services-card-description {
  font-size: 16px;
  color: #444444;
  margin: 0px;
  overflow: hidden;
  line-height: 2rem;
}

.managed-services-third-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(15, 68, 148, 1),
    rgba(44, 60, 111, 1)
  );
  margin: 40px 0px;
  padding: 30px 0px;
}

.managed-services-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.managed-services-third-heading {
  font-size: 43px;
  margin: 0px 0px 23px;
  color: white;
  text-align: center;
}

.managed-services-span {
  color: white;
  font-size: 25px;
  font-weight: normal;
  letter-spacing: 0.5px;
}

.managed-services-text-fields-container {
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.managed-services-third-text {
  color: #fff;
  font-size: 17px;
  line-height: 1.9rem;
  font-weight: 400;
  width: 24%;
  padding: 30px;
}

@media (max-width: 768px) {
  .managed-services-main-description {
    margin: 18px;
    width: 80%;
    line-height: 2rem;
  }
  .managed-services-card-main-heading {
    font-size: 40px;
    color: #000;
    margin: 0px 0px 23px;
    text-align: center;
    width: 70%;
    font-weight: 700;
  }
  .managed-services-card-container {
    width: 40%;
  }
  .managed-services-card-description {
    font-size: 14px;
    color: #444444;
    margin: 0px;
    overflow: hidden;
    line-height: 2rem;
  }
  .managed-services-card-heading {
    font-size: 22px;
    color: #134391;
    font-weight: 400;
    margin: 0px;
    font-style: normal;
    text-align: center;
  }
  .managed-services-bg-heading {
    color: #fff;
    font-size: 45px;
    margin: 0px;
  }
}
@media (max-width: 512px) {
  .managed-services-bg-sub-container {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  .managed-services-first-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    width: 100%;
    position: relative;
  }
  .managed-services-bg-heading {
    color: #fff;
    font-size: 30px;
    width: 80%;
    margin: 0px;
  }
  .managed-services-card-image {
    max-width: 60%;
    height: auto;
  }
  .managed-services-card-main-heading {
    font-size: 36px;
    color: #000;
    margin: 0px 0px 23px;
    text-align: center;
    width: 70%;
    font-weight: 700;
  }
  .managed-services-card-flex-container {
    padding-left: 0px;
  }

  .managed-services-main-description {
    margin: 16px;
    width: 80%;
    line-height: 2rem;
    color: #fff;
  }
  .managed-services-card-container {
    width: 95%;
  }
  .managed-services-text-fields-container {
    display: flex;
    justify-content: center;
    /* flex-direction: column; */
    flex-wrap: wrap;
    width: 90%;
  }
  .managed-services-third-text {
    color: #fff;
    font-size: 17px;
    line-height: 1.9rem;
    font-weight: 400;
    width: 80%;
    padding: 20px;
  }
  .managed-services-third-heading {
    font-size: 35px;
    margin: 0px 0px 23px;
    color: white;
    text-align: center;
  }
}
